const Block = ({ Name, Image, Description }) => {
  return (
    <div className="Block--Container--Item">
      <div className="Block--Item--Header">
        <span className="Block--Header--Image">{Image }</span>
        <h3>{Name} </h3>
      </div>
      <div className="Block--Item--Content">{Description}</div>
    </div>
  );
};
export default Block;
