// Import React Libs
import React from "react";
import { Routes, Route } from "react-router-dom";

// Import Pages
import Home from "./Pages/Home";
import NotFound from "./Pages/NotFound";

// Import Common Components
import ScrollToTop from "./Components/Common/ScrollToTop";
import Header from "./Components/Header";
import Footer from "./Components/Footer";

const App = () => {
  return(
    <div className="Debug--Mode">
      <Header />
      <Routes>
        <Route path='/*' element={<NotFound />} />
        <Route path='/' element={<Home />} />
        <Route path='/Home' element={<Home />} />
      </Routes>
      <Footer />
      <ScrollToTop />
    </div>
  );
}
export default App;