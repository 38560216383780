import logo from "../Assets/Images/logoWithName(white).png";
import { Link } from "react-router-dom";

const Header  = () => {
  return(
    <div className="Header--Container">
      <div className="Header--Container--Item">
        <img src={logo} alt="logo" />
      </div>
      <div className="Header--Container--Item">
        <div className="Header--Container--Item--NavBar">
          <Link to="/">О нас</Link>
          <Link to="/">Новости</Link>
          <Link to="/">Документы</Link>
          <Link to="/">Контакты</Link>
        </div>
      </div>
    </div>
  );
}
export default Header;