import { Link } from "react-router-dom";

const Footer  = () => {
  return(
    <div className="Footer--Container">
      <div className="Footer--Container--Item">
        <Link to="/">О нас</Link>
        <Link to="/">Новости</Link>
        <Link to="/">Документы</Link>
        <Link to="/">Контакты</Link>
      </div>
      <div className="Footer--Container--Item">
        <p>© 2021-2024 ЦЦТ "Горизонт Сатурна"</p>
      </div>
    </div>
  );
}
export default Footer;