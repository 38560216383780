// Import Components
import Block from "../Components/Block";
import { Info } from "lucide-react";

const Home = () => {
  const data = [
    {
      id: 1,
      Name: "Поддержка стартапов",
      Image: <Info />,
      Description: "Развитие продуктов в течение нескольких месяцев совместно с экспертами."
    },
    {
      id: 2,
      Name: "Курсы программирования",
      Image: <Info />,
      Description: "Обечение основам программирования на языках C++, C#. Вёрстка сайтов на HTML, CSS, JavaScript. Основы Ui/UX дизайна."
    },
    {
      id: 3,
      Name: "Развитие города Олёкминска",
      Image: <Info />,
      Description: "Внедрение ИТ решений в городскую среду."
    }
  ];

  return (
    <div className="Page">
      <h3>ЦЦТ "Горизонт Сатурна - это"</h3>
      {data.length > 0 && (
        <div className="Block--Container">
          {data.map((item) => (
            <Block
              key={item.id}
              Name={item.Name}
              Image={item.Image}
              Description={item.Description}
              dangerouslySetInnerHTML={{ __html: item.Description }}
            />            
          ))}
        </div>
      )}
    </div>
  );
}

export default Home;