// Import React Libs;
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
// Import Css
import './Assets/Css/Global.css';
import './Assets/Css/Reset.css';
import './Assets/Css/Modal.css';

import App from "./App";
// Render
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</React.StrictMode>
)
