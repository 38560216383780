

const NotFound = () => {
  return(
    <div className="Page">
      404 Not Found
    </div>
  );
}
export default NotFound;

